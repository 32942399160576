import Default from "@/layouts/Default.vue";
import Home from "@/layouts/Home.vue";
import Page from "@/layouts/Page.vue"
import Demo from "@/layouts/Demo.vue"
export default {
  'default': Default,
  'home': Home,
  'page': Page,
  'demo': Demo
}
